"use client";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IProfile } from "@/services/types";
import { message } from "antd";
import { useRouter, useSearchParams } from "next/navigation";
import { AppRoutes } from "@/config/app/routes";
import { useLocalStorage } from "usehooks-ts";
import {
  LOCAL_STORAGE_CREDENTIALS,
  LOCAL_STORAGE_KEYS,
} from "@/utils/app/constants";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import axios, { AxiosError } from "axios";
import { ApiRoutes } from "@/services/routes";
import { setCookie } from "cookies-next";
import useNativeAppBridge from "@/bridge/useNativeAppBridge";

interface AuthenticationProviderProps {
  children: React.ReactNode;
}
export enum AuthMethod {
  credentials = "credentials",
  apiKey = "apiKey",
  none = "none",
}
export enum LoginState {
  checking = "checking",
  active = "active",
  expired = "expired",
  try_refresh_token = "try_refresh_token",
  user_inactive = "user_inactive",
  not_logged_in = "not_logged_in",
  cannot_login = "cannot_login",
}

interface LoginMethodParameters {
  method: AuthMethod;
  parameters: { username: string; password: string } | { apiKey: string };
}

interface AuthContextState {
  loginState: LoginState;
  SAVED_CREDENTIALS: LOCAL_STORAGE_CREDENTIALS | undefined;
  user: IProfile | undefined;
  setUser: (profile?: IProfile) => void;
  method: AuthMethod;
  API_KEY?: string;
  ACCESS_COOKIE?: string;
  REFRESH_COOKIE?: string;
  logout: () => void;
  verifyAccount: (
    email: string,
    token: string,
    onSuccess: () => void,
    onError: () => void
  ) => void;
  resendVerify: (email: string) => void;
  forgetPassword: (email: string) => void;
  login: (params: LoginMethodParameters) => void;
  register: (params: any) => void;
  reset_password: (params: any) => void;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

enum COOKIES_NAME {
  access = "access",
  refresh = "refresh",
}

export const AuthenticationProvider = ({
  children,
}: AuthenticationProviderProps) => {
  const { login: nativeAppLogin } = useNativeAppBridge();
  const { API_URL } = useEnvironment();
  const queryClient = useQueryClient();
  const { replace } = useRouter();
  const searchParams = useSearchParams();
  const redirectAfterLoginUrl =
    searchParams.get("redirect") || AppRoutes.companies;

  const [user, setUser] = useState<IProfile | undefined>(undefined);
  const [apiKey, setApiKey] = useLocalStorage(LOCAL_STORAGE_KEYS.API_KEY, "", {
    initializeWithValue: false,
  });
  const API_KEY = apiKey === "" ? undefined : apiKey;
  const [SAVED_CREDENTIALS, setSavedCredentials] = useLocalStorage<
    LOCAL_STORAGE_CREDENTIALS | undefined
  >(LOCAL_STORAGE_KEYS.CREDENTIALS, undefined, {
    initializeWithValue: false,
  });
  const [ACCESS_COOKIE, saveAccessToken] = useLocalStorage<string | undefined>(
    LOCAL_STORAGE_KEYS.ACCESS,
    undefined,
    {
      initializeWithValue: false,
    }
  );
  const [REFRESH_COOKIE, saveRefreshCookie] = useLocalStorage<
    string | undefined
  >(LOCAL_STORAGE_KEYS.REFRESH, undefined, {
    initializeWithValue: false,
  });

  const {
    isLoading: isChecking,
    refetch,
    data: { loginState, authMethod },
  } = useQuery({
    queryKey: ["auth-state"],
    initialData: () => ({
      loginState: LoginState.checking,
      authMethod: AuthMethod.none,
    }),
    queryFn: async () => {
      let _finalAuthMethod: AuthMethod = AuthMethod.none;
      let _finalLoginState: LoginState = LoginState.not_logged_in;
      try {
        if (API_KEY) {
          const axiosInstance = axios.create({
            baseURL: API_URL,
            headers: {
              "x-api-key": API_KEY,
            },
          });
          await axiosInstance.get(ApiRoutes.profile.me);
          _finalAuthMethod = AuthMethod.apiKey;
          _finalLoginState = LoginState.active;
          nativeAppLogin(AuthMethod.apiKey, { apiKey: API_KEY });
        }
        if (ACCESS_COOKIE) {
          const axiosInstance = axios.create({
            baseURL: API_URL,
            headers: {
              Authorization: `token ${ACCESS_COOKIE}`,
            },
          });

          await axiosInstance.get(ApiRoutes.profile.me);
          setCookie(COOKIES_NAME.access, ACCESS_COOKIE);
          _finalAuthMethod = AuthMethod.credentials;
          _finalLoginState = LoginState.active;
          nativeAppLogin(AuthMethod.credentials, {
            accessToken: ACCESS_COOKIE,
            refreshToken: REFRESH_COOKIE!,
          });
        }
      } catch (error: any) {
        const errorResponse = error as AxiosError<any>;
        if (
          errorResponse?.response?.status === 403 &&
          errorResponse?.response?.data?.code === "user_inactive"
        ) {
          _finalLoginState = LoginState.user_inactive;
          nativeAppLogin("inactive", undefined);
        }
        if (
          errorResponse?.response?.status === 403 &&
          errorResponse?.response?.data?.code === "token_not_valid"
        ) {
          try {
            const axiosInstance = axios.create({
              baseURL: API_URL,
              headers: {
                Authorization: `token ${ACCESS_COOKIE}`,
              },
            });
            const {
              data: { access },
            } = await axiosInstance.post(ApiRoutes.refresh, {
              refresh: REFRESH_COOKIE,
            });
            saveAccessToken(access);
            _finalLoginState = LoginState.active;
            nativeAppLogin(AuthMethod.credentials, {
              accessToken: access,
              refreshToken: REFRESH_COOKIE!,
            });
          } catch (e) {
            _finalLoginState = LoginState.expired;
            nativeAppLogin("token_expired", undefined);
          }
        }
        if (errorResponse?.response?.status === 401) {
          _finalLoginState = LoginState.try_refresh_token;
        }
      }
      return {
        loginState: _finalLoginState,
        authMethod: _finalAuthMethod,
      };
    },
  });

  const logout = useCallback(() => {
    replace(AppRoutes.login);

    // Delete cookies
    saveAccessToken(undefined);
    saveRefreshCookie(undefined);
    setApiKey("");
    setUser(undefined);

    queryClient.setQueryData(["auth-state"], () => ({
      loginState: LoginState.not_logged_in,
      authMethod: AuthMethod.none,
    }));
    setSavedCredentials(undefined);
  }, [saveRefreshCookie, saveAccessToken, replace, setApiKey]);

  const login = useCallback(
    async ({ method, parameters }: LoginMethodParameters) => {
      if (method === AuthMethod.apiKey && "apiKey" in parameters) {
        try {
          const axiosInstance = axios.create({
            baseURL: API_URL,
            headers: {
              "x-api-key": parameters?.apiKey,
            },
          });
          await axiosInstance.get(ApiRoutes.profile.me);
          setApiKey(parameters.apiKey);
          saveAccessToken(undefined);
          saveRefreshCookie(undefined);
          replace(redirectAfterLoginUrl);
          await queryClient.setQueryData(["auth-state"], () => ({
            loginState: LoginState.active,
            authMethod: AuthMethod.apiKey,
          }));
        } catch (e) {
          message.error("API Key không hợp lệ");
          await queryClient.setQueryData(["auth-state"], () => ({
            loginState: LoginState.not_logged_in,
            authMethod: AuthMethod.apiKey,
          }));
        }
      }
      if (method === AuthMethod.credentials && "username" in parameters) {
        try {
          const axiosInstance = axios.create({
            baseURL: API_URL,
          });
          const response = await axiosInstance.post(ApiRoutes.signin, {
            username: parameters.username,
            password: parameters.password,
          });
          saveAccessToken(response.data.access);
          saveRefreshCookie(response.data.refresh);
          setApiKey("");
          replace(redirectAfterLoginUrl);
          await queryClient.setQueryData(["auth-state"], () => ({
            loginState: LoginState.active,
            authMethod: AuthMethod.credentials,
          }));
          setSavedCredentials({
            username: parameters.username,
            password: parameters.password,
          });
        } catch (e: any) {
          if (e?.response) {
            if (e?.response?.status === 401) {
              message.error("Thông tin đăng nhập sai vui lòng nhập lại.");
            } else if (e?.response?.data?.detail) {
              message.error(e?.response?.data?.detail);
            } else {
              message.error("Đăng nhập không thành công");
            }
          } else {
            message.error("Đăng nhập không thành công");
          }

          await queryClient.setQueryData(["auth-state"], () => ({
            loginState: LoginState.not_logged_in,
            authMethod: AuthMethod.credentials,
          }));
          setSavedCredentials(undefined);
        }
      }
    },
    [
      API_URL,
      setApiKey,
      replace,
      saveAccessToken,
      saveRefreshCookie,
      redirectAfterLoginUrl,
    ]
  );

  const verifyAccount = useCallback(
    async (
      email: string,
      token: string,
      onSuccess: () => void,
      onError: () => void
    ) => {
      try {
        const axiosInstance = axios.create({
          baseURL: API_URL,
        });
        await axiosInstance.post(ApiRoutes.verify_email, {
          email,
          token,
        });
        message.success("Tài khoản đã được xác thực");
        // replace(AppRoutes.companies);
        onSuccess();
      } catch (e: any) {
        message.error(e?.response?.data?.error_messages || "Lỗi xác thực");
        onError();
      }
    },
    [API_URL, replace]
  );

  const resendVerify = useCallback(
    async (email: string) => {
      try {
        const axiosInstance = axios.create({
          baseURL: API_URL,
        });
        await axiosInstance.post(ApiRoutes.resend_email, {
          email,
        });
        message.success("Mã xác thực đã được gửi tới email của bạn");
      } catch (e: any) {
        message.error(e?.response?.data?.error_messages || "Lỗi gửi đi");
      }
    },
    [API_URL]
  );

  const forgetPassword = useCallback(
    async (email: string) => {
      try {
        const axiosInstance = axios.create({
          baseURL: API_URL,
        });
        await axiosInstance.post(ApiRoutes.forget_password, {
          email,
        });
        message.success("Mật khẩu đã được gửi tới email của bạn");
        return {
          success_messages: "Mật khẩu đã được gửi tới email của bạn",
        };
      } catch (e: any) {
        message.error(e?.response?.data?.error_messages || "Lỗi gửi đi");

        return {
          error_messages: e?.response?.data?.error_messages || "Lỗi đăng ký",
        };
      }
    },
    [API_URL]
  );

  const register = useCallback(
    async (params: Record<string, string>) => {
      try {
        const axiosInstance = axios.create({
          baseURL: API_URL,
        });
        const response = await axiosInstance.post(ApiRoutes.signup, {
          ...params,
        });

        // const companiesArray = response?.data?.companies;
        // const lastCompanyArray =
        //   companiesArray && companiesArray.length > 0
        //     ? companiesArray[companiesArray.length - 1]
        //     : null;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await login({
          method: AuthMethod.credentials,
          parameters: {
            username: params?.email,
            password: params?.password,
          },
        });
        localStorage.setItem(
          "COMPANY_SLUG",
          JSON.stringify(response?.data?.companies?.[0]?.id)
        );
      } catch (e: any) {
        message.error(e?.response?.data?.error_messages || "Lỗi đăng ký");
      }
    },
    [API_URL, login]
  );

  const reset_password = useCallback(
    async (params: Record<string, string>) => {
      try {
        const axiosInstance = axios.create({
          baseURL: API_URL,
        });
        await axiosInstance.post(ApiRoutes.reset_password, {
          ...params,
        });
        message.success("Cập nhật mật khẩu thành công");
        // replace(AppRoutes.companies);
      } catch (e: any) {
        message.error(e?.response?.data?.error_messages || "Lỗi xác thực");
      }
    },
    [API_URL]
  );

  const _loginState = isChecking ? LoginState.checking : loginState;

  useEffect(() => {
    // Weird bug when integrate TRPC, need to reset this query on client side
    if (typeof window !== "undefined") {
      refetch();
    }
  }, [typeof window, API_KEY, ACCESS_COOKIE, REFRESH_COOKIE]);

  return (
    <AuthContext.Provider
      value={{
        register,
        reset_password,
        user,
        setUser,
        method: authMethod,
        API_KEY,
        ACCESS_COOKIE,
        SAVED_CREDENTIALS,
        REFRESH_COOKIE,
        logout,
        login,
        loginState: _loginState,
        verifyAccount,
        resendVerify,
        forgetPassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthentication(): AuthContextState {
  const context = useContext<any>(AuthContext);

  if (!context) {
    throw Error(
      "useAuthentication must be used within a AuthenticationProvider"
    );
  }
  return context;
}
